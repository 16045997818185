<template>
    <Nav/>
    <div id="project">
        <h3 class="projectTitle">{{ projectData.project }}</h3>
        <p class="date">{{ projectData.date }}</p>
        <div class="cols">
            <div class="description" v-html="projectData.description"></div>
            <div>
                <div class="info">
                    <img :src="projectData.image" class="featuredImage">
                    <p v-html="projectData.collaborators"></p>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    import Nav from "../components/Nav";

    export default {
        name: 'Project',
        components: {
            Nav
        },
        data() {
            return {
                allData: [],
                projectData: []
            }
        },
        mounted() {
            const request = new XMLHttpRequest();
            request.open("GET", "/iris-portfoliodata.json", false);
            request.send(null)
            this.allData = (JSON.parse(request.responseText));

            for (var i = 0; i < this.allData.length; i++) {
                if (this.allData[i].slug == this.$route.params.project_slug) {
                    this.projectData = this.allData[i]
                }
            }
        }
    }
</script>

<style scoped lang="scss">


    #project {
        width: 60%;
        max-width: 1140px;
        margin: 0 auto;
    }

    .cols {
        display: grid;
        grid-template-columns: 2fr 1fr;
        gap: 32px 32px;
    }

    .featuredImage {
        width: 100%;
    }

    .projectTitle {
        margin-bottom: 0;
    }

    .description {
        font-family: "Roboto Slab", sans-serif;
        line-height: 1.25;
        margin-top: 0;
    }

    .date {
        font-style: italic;
        margin: 0;
    }

    .info {
        background-color: white;
        padding: 20px;
        box-shadow: 8px 8px 10px rgba(64, 64, 64, 0.4);
    }
</style>