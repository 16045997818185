<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>

</script>

<style lang="scss">
    @font-face {
        font-family: Merriweather;
        src: url("assets/Merriweather-Regular.ttf");
        font-weight: normal;
    }
    @font-face {
        font-family: Merriweather;
        src: url("assets/Merriweather-Bold.ttf");
        font-weight: bold;
    }
    @font-face {
        font-family: Roboto Slab;
        src: url("assets/RobotoSlab-Regular.ttf");
        font-weight: normal;
    }
    @font-face {
        font-family: Roboto Slab;
        src: url("assets/RobotoSlab-Bold.ttf");
        font-weight: bold;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        scroll-behavior: smooth;
        overflow-x: hidden;
        background: #fff5e6;

    }

    body {
        margin: 0;
    }

    a {
        text-decoration: none;
        color: inherit;
    }

    h1 {

    }

    h2 {
        font-size: 44px;
    }

    h3 {
        font-size: 32px;
    }

    p {
        font-size: 20px;
    }

    .externalLink {
        color: #dd4747;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
        }
    }
</style>
