<template>
	<div id="nav">
		<div class="title">
			<router-link :to="{ name: 'About' }">
				<h3>Irisdelia Garcia</h3>
			</router-link>
		</div>
		<div class="menu">
			<router-link :to="{ name: 'About' }">
				<p class="menu-item">About</p>
			</router-link>
			<router-link :to="{ name: 'Portfolio' }">
				<p class="menu-item">Portfolio</p>
			</router-link>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'Nav'
	}
</script>

<style scoped lang="scss">
	#nav {
		width: 60%;
		max-width: 1140px;
		margin: 0 auto;

		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 32px;

		padding-top: 32px;
		border-bottom: 1px solid black;
	}

	.title {
		display: flex;
		h3 {
			margin: 0;
		}
	}

	.menu {
		display: flex;
		justify-content: flex-end;
        align-items: flex-end;

		.menu-item {
			margin: 0;
			padding-left: 32px;
            font-size: 24px;

        }
	}
</style>
