<template>
	<Nav/>
	<div id="portfolio">
		<h3>Portfolio</h3>
		<div class="portfolioGrid">
			<div v-for="project in portfolioData" :key="project.project" class="projectBox">
				<router-link :to="{ name: 'Project', params: { project_slug: project.slug } }">
					<img class="featuredImage" :src="project.image">
					<p class="projectTitle">{{project.project}}</p>
				</router-link>
			</div>
		</div>
	</div>
</template>

<script>
	import Nav from "../components/Nav";

	export default {
		name: 'Portfolio',
		components: {
			Nav
		},
		data() {
			return {
				portfolioData: []
			}
		},
		created() {
			const request = new XMLHttpRequest();
			request.open("GET", "iris-portfoliodata.json", false);
			request.send(null)
			this.portfolioData = (JSON.parse(request.responseText));

			this.portfolioData = this.portfolioData.sort((a, b) => {
			let fa = a.id,
				fb = b.id;

			if (fa < fb) {
				return 1;
			}
			if (fa > fb) {
				return -1;
			}
			return 0;
			})
		}
	}
</script>

<style scoped lang="scss">
	#portfolio {
		width: 60%;
		max-width: 1140px;
		margin: 0 auto;
	}

	.portfolioGrid {
		display: grid; 
		grid-template-columns: 1fr 1fr 1fr; 
		grid-template-rows: 1fr 1fr 1fr; 
		gap: 32px 32px; 
	}


	.featuredImage {
		width: 300px;
		height: 300px;
		object-fit: cover;
	}

	.projectTitle {
		font-weight: bold;
        font-size: 22px;
        max-width: 300px;
        font-family: Merriweather, sans-serif;
	}
</style>
