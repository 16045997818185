<template>
	<Nav/>
	<div id="about">
		<h3>About</h3>
		<div class="aboutContent">
			<img class="profilePhoto" src="https://awikhigan.irlh.org/gen/wl/?id=tUrDmAMpw78EIgnIIfISgLqu44pAqotw">
            <div class="text">
                <p class="bio">Irisdelia Garcia is a Puerto Rican interdisciplinary artist, writer and director from The Bronx, NY. She focuses on the body as an archive, languageless histories and deconstructing colonialism as daily practice. In 2018, she completed her project the digital vaivén, which engages with post-colonial Puerto Rican identity through simulation theory, discourse around virtual and augmented realities and poetry. Her poem “Dear Maria,” featured in “the digital vaivén” won The Academy of American Poets College Award through the Amherst College English Department.</p>
                <p class="bio">Garcia was an inaugural Generation NYZ Creative Fellow at <a href="https://www.pingchong.org/" target="_blank" class="externalLink">Ping Chong and Company</a> from September 2019 to February 2020 and is now a collaborative artist and teacher for the theater. Her work includes “La Ira and Other Ghosts” first presented during her EMERGENYC Residency at the Hemispheric Institute and “qué bien te ves,” developed through Ping Chong and Company‘s “Nocturne Remix 2020” anthology. Garcia has been director and writer for digital plays “call me when you get this” (November 2020) and her one-act “Christopher Columbus Is In The Waiting Room” (February 2020) at The Tank. Her video performance piece “i had swallowed mirrors for you” (December 2020) is part of Ping Chong and Company's "A Universal History of Infamy" series.</p>
                <p class="bio">She is a member of Queer and Now‘s Digital Cabarets series, member of <a href="https://irlhumanities.org/" target="_blank" class="externalLink">Immersive Realities Labs for the Humanities (irLh)</a> and a past troupe member of La Pocha Nostra. Graduating summa cum laude, Garcia holds a BA in English with a concentration in Digital Humanities from Amherst College and a Multicultural Theater Practice Certificate from the Five College Consortium. She will be pursuing her MFA in Contemporary Theatre and Performance at The New School. Garcia is an Amherst College 2021-2022 Roland Wood Fellowship recipient for master-level study in the arts.</p>
            </div>
		</div>
	</div>
</template>

<script>
	import Nav from "../components/Nav";

	export default {
		name: 'About',
		components: {
			Nav
		}
	}
</script>

<style scoped lang="scss">
	#about {
		width: 60%;
		max-width: 1140px;
		margin: 0 auto;
	}

	.aboutContent {
		display: grid;
		grid-template-columns: 1fr 2fr;
		gap: 32px 32px;
	}

	.profilePhoto {
		width: 100%;
	}

    .bio {
        font-family: "Roboto Slab", sans-serif;
        line-height: 1.25;
        margin-top: 0;
    }

</style>
