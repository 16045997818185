import { createRouter, createWebHistory } from 'vue-router'

import About from '../pages/About.vue'
import Portfolio from '../pages/Portfolio.vue'
import Project from '../pages/Project.vue'


const routes = [
  {
    path: '/',
    name: 'About',
    component: About
  },
  {
    path: '/portfolio',
    name: 'Portfolio',
	component: Portfolio
  },
  {
	  path: '/portfolio/:project_slug',
	  name: 'Project',
	  component: Project,
	  props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router